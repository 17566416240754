import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Candy, Midnight } from '../../styles/colours';
import * as Input from '../input';
import { SM, XS } from '../../styles/responsiveness';

export const Container = styled.div`
  text-align: center;
  padding: 60px 0;

  ${SM} {
    max-width: 420px;
    margin: 0 auto;
    padding: 30px 12px;
  }
`;

export const Title = styled.div`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;
  max-width: 515px;
  margin: 0 auto 40px auto;

  ${SM} {
    font-size: 22px;
    letter-spacing: -0.55px;
    margin-bottom: 20px;
  }
`;

export const Intro = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  margin: 0 auto 30px auto;

  ${SM} {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const Disclaimer = styled.div`
  color: #718594;
  font-size: 14px;
  line-height: 18px;
`;

export const DisclaimerAstrix = styled.div`
  display: inline-block;
  margin-left: -20px;
  position: relative;
  top: -3px;
  right: -5px;

  svg {
    height: 10px;
    opacity: 0.5;
    g {
      fill: #718594;
    }
  }
`;

export const CallToActionRegion = styled.div`
  padding: 24px 0;
`;

export const Outro = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OutroContent = styled.div`
  max-width: 280px;
  margin-left: 15px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;

  ${XS} {
    font-size: 10px;
  }
`;

export const OutroLink = styled(Link)`
  font-style: italic;
  text-decoration: underline;
  color: ${Midnight};

  @media (hover: hover) {
    &:hover {
      color: ${Candy};
    }
  }
`;

export const InputRegion = styled.div`
  max-width: 370px;
  margin: 0 auto;

  ${Input.Container} {
    font-size: 18px;
    box-shadow: 0 2px 4px 0 #eaecee;
  }
`;

export const ComparisonRegion = styled.div``;
