import React from 'react';
import CenterContainer from '../center-container';
import {
  Container,
  Content,
  Title,
  SubTitle,
  Lessons,
  Lesson,
  LessonCard,
  LessonCardContent,
  LessonNumber,
  LessonTitle,
  LessonActionRegion,
  LessonLearnButton,
  LessonDuration,
  CallToActionRegion,
  ImageSpacer
} from './style';
import { Logo } from '../logo';
import { ArrowForward } from '../material-icon';
import AccessTimeIcon from '../access-time-icon';
import { CandyInvertedButton } from '../button';
import * as links from '../../utilities/links';

export default () => {
  return (
    <Container>
      <Content>
        <CenterContainer>
          <Title>Become a master at home loans.</Title>
          <SubTitle>
            If you’re unsure about all this home loan talk, here’s what you need
            to know.
          </SubTitle>
          <CallToActionRegion>
            <CandyInvertedButton to={links.learn()}>
              Read more!
              <ArrowForward />
            </CandyInvertedButton>
          </CallToActionRegion>
          <Lessons>
            <Lesson>
              <LessonCard to={links.learn({ lesson: 1 })}>
                <LessonNumber>Lesson 01</LessonNumber>
                <LessonCardContent>
                  <LessonTitle>Why is every home loan different?</LessonTitle>
                  <LessonActionRegion>
                    <LessonLearnButton>
                      <ArrowForward />
                      Learn
                    </LessonLearnButton>
                    <LessonDuration>
                      <AccessTimeIcon />
                      &nbsp;2 mins
                    </LessonDuration>
                  </LessonActionRegion>
                </LessonCardContent>
              </LessonCard>
            </Lesson>
            <Lesson>
              <LessonCard to={links.learn({ lesson: 2 })}>
                <LessonNumber>Lesson 02</LessonNumber>
                <LessonCardContent>
                  <LessonTitle>How do I know what I can afford?</LessonTitle>
                  <LessonActionRegion>
                    <LessonLearnButton>
                      <ArrowForward />
                      Learn
                    </LessonLearnButton>
                    <LessonDuration>
                      <AccessTimeIcon />
                      &nbsp;2 mins
                    </LessonDuration>
                  </LessonActionRegion>
                </LessonCardContent>
              </LessonCard>
            </Lesson>
            <Lesson>
              <LessonCard to={links.learn({ lesson: 3 })}>
                <LessonNumber>Lesson 03</LessonNumber>
                <LessonCardContent>
                  <LessonTitle>
                    Did you know home loans can be compared?
                  </LessonTitle>
                  <LessonActionRegion>
                    <LessonLearnButton>
                      <ArrowForward />
                      Learn
                    </LessonLearnButton>
                    <LessonDuration>
                      <AccessTimeIcon />
                      &nbsp;2 mins
                    </LessonDuration>
                  </LessonActionRegion>
                </LessonCardContent>
              </LessonCard>
            </Lesson>
            <Lesson>
              <LessonCard to={links.learn({ lesson: 4 })}>
                <LessonNumber>Lesson 04</LessonNumber>
                <LessonCardContent>
                  <LessonTitle>What do I pay and what can I save?</LessonTitle>
                  <LessonActionRegion>
                    <LessonLearnButton>
                      <ArrowForward />
                      Learn
                    </LessonLearnButton>
                    <LessonDuration>
                      <AccessTimeIcon />
                      &nbsp;2 mins
                    </LessonDuration>
                  </LessonActionRegion>
                </LessonCardContent>
              </LessonCard>
            </Lesson>
          </Lessons>
        </CenterContainer>
      </Content>
      <ImageSpacer />
    </Container>
  );
};
