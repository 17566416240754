import React from 'react';
import { CallToActionRegion, CallToActionButton } from './style';
import { Container, Title, Intro } from '../../../../components/splash-bar';
import Crowned from '../../../../components/crowned-letter';
import * as links from '../../../../utilities/links';
import highlightButton from './highlight-button.png';

export default () => (
  <Container animate={true}>
    <Title>
      The b<Crowned>e</Crowned>st home loan experience online.
    </Title>
    <Intro>oh … and it’s a 100% FREE</Intro>
    <CallToActionRegion>
      <CallToActionButton to={links.learn()}>
        <strong>
          <span>Learn</span> about home loans
        </strong>
        All the jargon simplified
      </CallToActionButton>
      <CallToActionButton to={links.prequalify()}>
        <strong>
          <span>Prequalify</span> for a home loan
        </strong>
        Know your buying power
      </CallToActionButton>
      <CallToActionButton to={links.apply()}>
        <img src={highlightButton} alt="Highlight button" />
        <strong>
          <span>Apply</span> now to 7 institutions
        </strong>
        Do your full home loan application online
      </CallToActionButton>
    </CallToActionRegion>
  </Container>
);
