import styled from '@emotion/styled';
import { Candy } from '../../styles/colours';
import { Logo } from '../logo';
import TitleShapesContainer from '../tiled-shapes-container';
import RatingBase from '../rating';
import { SM } from '../../styles/responsiveness';
import { EaseOutCirc } from '../../styles/easing';

export const Container = styled.div`
  text-align: center;
  padding: 50px 0;
  overflow: hidden;

  ${SM} {
    padding: 30px 15px;
  }
`;

export const Title = styled.div`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;

  ${SM} {
    font-size: 22px;
    line-height: 25px;
  }
`;

export const SubTitle = styled.div`
  font-family: 'Source Sans Pro', serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  padding: 16px 0;

  ${SM} {
    font-size: 15px;
  }
`;

export const Items = styled.div`
  padding: 30px 0;
  margin: 0 auto;
  width: 300px;
  user-select: none;

  @media (min-width: 600px) {
    width: 600px;
  }
  @media (min-width: 900px) {
    width: 900px;
  }

  .alice-carousel__wrapper {
    overflow: visible !important;
  }

  .alice-carousel__dots-item {
    background-color: #fff3fa;
  }
  .alice-carousel__dots-item:hover,
  .alice-carousel__dots-item.__active {
    background-color: ${Candy};
  }
  .alice-carousel__dots {
    margin: 0;
  }
  .alice-carousel__stage {
    transition-duration: 1000ms !important;
    transition-timing-function: ${EaseOutCirc} !important;
  }
`;

export const Item = styled.div`
  height: 350px;
  flex-shrink: 0;
  flex-grow: 0;
  padding-bottom: 30px;
  max-width: 300px;
`;

export const ItemInner = styled.div`
  height: 100%;
  margin: 0 8px;
  border: 1px solid #e2e8ed;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 #e2e8ed;

  position: relative;
`;

export const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 35px 0 10px 0;
`;

export const Rating = styled(RatingBase)`
  margin-bottom: 20px;
  font-size: 16px;

  > * {
    padding: 0 2px;
  }
`;

export const Content = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 0 30px;
`;

export const SavingSection = styled(TitleShapesContainer)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  padding: 23px 45px;
`;

export const SavedLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-shadow: 0 0 6px 0 #f2f3f4;
  margin: 0 0 2px 2px;
`;

export const SavingAmount = styled.div`
  font-size: 34px;
  line-height: 42px;
  text-shadow: 0 2px 0 0 #eaecee;
  margin-bottom: 5px;
  position: relative;
`;

export const SavingAstrix = styled(Logo)`
  position: absolute;
  top: -10px;
  left: -20px;
  width: 15px;
`;

export const Interest = styled.div`
  border-radius: 3px;
  background-color: #eaecee;
  font-size: 13px;
  line-height: 17px;
  display: inline-block;
  padding: 2px 7px 3px 7px;
  margin-bottom: 5px;
`;

export const InterestLabel = styled.span`
  font-weight: 600;
`;

export const InterestRate = styled.span`
  color: ${Candy};
  font-weight: 300;
`;
