export default ({ price, rate, term }) => {
  const ratePerMonth = rate / 100 / 12;
  const termInMonths = term * 12;
  const monthly = (
    (ratePerMonth * price) /
    (1 - Math.pow(1 + ratePerMonth, -termInMonths))
  ).toFixed(2);
  const total = (monthly * termInMonths).toFixed(2);
  return {
    monthly,
    total
  };
};
