import styled from '@emotion/styled/macro';
import { Logo } from '../logo';
import { SM, Max900 } from '../../styles/responsiveness';
import { Midnight, Mountain, U1 } from '../../styles/colours';
import { Switch, SwitchShadow } from '../calculator-button';

export const Section = styled.section`
  max-width: 960px;
  padding: 80px 0 20px 0;
  margin: 0 auto;

  ${SM} {
    max-width: 360px;
    padding: 30px 15px 0 15px;
  }
`;

export const Heading = styled.h1`
  font-family: 'Source Serif Pro', serif;
  padding-bottom: 30px;
  color: ${Midnight};
  font-size: 58px;
  font-weight: bold;
  letter-spacing: -1.45px;
  line-height: 52px;
  text-align: center;

  ${SM} {
    font-size: 22px;
    line-height: 1;
  }
`;

export const Title = styled.h2`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;
  margin: 40px 0;

  ${Max900} {
    font-size: 22px;
  }
`;

export const Label = styled.p`
  color: ${Mountain};
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;

export const Step = styled.div`
  padding: 10px 0;
  display: flex;

  ${SM} {
    padding: 20px 0;
    flex-direction: column-reverse;
  }
`;

export const StepFlex = styled.div`
  flex: 1 1 50%;
  display: flex;
  padding-top: 30px;
  justify-content: ${({ align }) => align || 'flex-start'};

  ${SM} {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    order: ${({ order }) => order || 1};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StepFlexContent = styled.div``;

export const Spacer = styled.div`
  height: ${({ height }) => height || 40}px;

  ${SM} {
    height: 0px;
  }
`;

export const StepImage = styled.img`
  height: ${({ height }) => height || 700}px;

  ${SM} {
    width: 100%;
    height: auto;
  }
`;

export const Astrix = styled(Logo)`
  width: 25px;
  margin-left: 4px;
`;

export const DisclaimerAstrix = styled(Logo)`
  height: 10px;
  opacity: 0.5;
  g {
    fill: #718594;
  }
`;

export const CalculatorButtonsRegion = styled.div`
  margin: 20px 0 0 0;
  display: inline-block;
`;

export const ButtonRegion = styled.div`
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${Switch} {
    ${SwitchShadow};
  }
`;
