import React, { useMemo } from 'react';
import {
  Container,
  Title,
  SubTitle,
  Items,
  Item,
  Name,
  Content,
  Interest,
  InterestLabel,
  InterestRate,
  SavedLabel,
  SavingAmount,
  SavingAstrix,
  SavingSection,
  ItemInner
} from './style';
import Testimonials from './content';
import Currency from '../currency';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsiveConfig = {
  0: {
    items: 1
  },
  600: {
    items: 2
  },
  900: {
    items: 3
  }
};

const CarouselComponent = () => {
  return (
    <AliceCarousel
      mouseDragEnabled
      responsive={responsiveConfig}
      buttonsDisabled={true}
      autoPlayInterval={5000}
      keysControlDisabled={true}
      disableAutoPlayOnAction={true}
    >
      {Testimonials.map(({ id, name, content, amount, rate }) => (
        <Item key={id}>
          <ItemInner>
            <Name>{name}</Name>
            <Content>{content}</Content>
            <SavingSection>
              <SavedLabel>Saved</SavedLabel>
              <SavingAmount>
                <SavingAstrix />
                <Currency>{amount}</Currency>&nbsp;p/m
              </SavingAmount>
              <Interest>
                <InterestLabel>Interest Rate:&nbsp;</InterestLabel>
                <InterestRate>{rate}%</InterestRate>
              </Interest>
            </SavingSection>
          </ItemInner>
        </Item>
      ))}
    </AliceCarousel>
  );
};

export default ({
  title = 'We love our clients, and they love us.',
  subTitle = "What's not to like about that?"
}) => {
  const carousel = useMemo(() => <CarouselComponent />, []);
  return (
    <Container>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <Items>{carousel}</Items>
    </Container>
  );
};
