import styled from '@emotion/styled/macro';
import { Candy, Midnight } from '../../styles/colours';
import { SM } from '../../styles/responsiveness';
import { Container as MaterialIcon } from '../material-icon';

export const Container = styled.section`
  position: relative;
`;

export const Content = styled.div`
  text-align: center;
  max-width: 680px;
  padding-top: 80px;
  margin: 0 auto;

  ${SM} {
    max-width: 360px;
    padding: 30px 15px 0 15px;
  }
`;

export const Title = styled.h2`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;
  padding-bottom: 10px;

  ${SM} {
    font-size: 22px;
  }
`;

export const Text = styled.p`
  font-family: 'Source Sans Pro', serif;
  color: ${Midnight};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
`;

export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto;
`;

export const Feature = styled.div`
  flex: 1 1 30%;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #d5dade;
  background-color: #ffffff;
  box-shadow: 0 16px 14px 0 rgba(113, 133, 148, 0.42), 0 3px 0 0 #d5dade;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  ${MaterialIcon} {
    color: ${Candy};
    font-size: 30px;
  }
`;

export const CalculatorIconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #d5dade;
  background-color: #ffffff;
  box-shadow: 0 16px 14px 0 rgba(113, 133, 148, 0.42), 0 3px 0 0 #d5dade;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const DiveIn = styled.img`
  position: absolute;
  bottom: -20px;
  height: 400px;
  left: 0;

  ${SM} {
    height: auto;
    width: 90%;
  }
`;

export const ImageSpacer = styled.div`
  height: 400px;

  ${SM} {
    height: 100px;
  }
`;

export const ThatsYouWrapper = styled.span`
  position: relative;

  img {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 60px;
    transform: translateX(-50%);

    ${SM} {
      display: none;
    }
  }
`;
