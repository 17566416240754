import styled from '@emotion/styled/macro';
import { keyframes, css } from '@emotion/core';
import { Candy } from '../../styles/colours';
import { SM, XS } from '../../styles/responsiveness';

const TitleFadeInAnimation = keyframes`
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const TitleAnimate = css`
  opacity: 0;
  animation: ${TitleFadeInAnimation} 2.2s cubic-bezier(0.36, 0, 0, 1.01)
    forwards;
  animation-delay: 0.4s;
`;

export const Title = styled.h1`
  font-family: 'Source Serif Pro', serif;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -1.45px;
  line-height: 56px;
  text-align: center;
  max-width: 680px;
  margin: 30px auto;

  ${SM} {
    font-size: 36px;
    letter-spacing: -0.9px;
    line-height: 33px;
    max-width: 385px;
  }
`;

export const Intro = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  max-width: 450px;
  margin: 0 auto 80px auto;

  em {
    font-style: normal;
    position: relative;

    &::after {
      content: ' ';
      border-bottom: solid 2px ${Candy};
      position: absolute;
      left: 3px;
      right: 3px;
      bottom: -1px;
    }
  }

  ${SM} {
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    margin-bottom: 30px;
  }
`;

const IntroFadeInAnimation = keyframes`
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const IntroAnimate = css`
  opacity: 0;
  animation: ${IntroFadeInAnimation} 1s forwards;
  animation-delay: 0.5s;
`;

const ContainerAnimate = css`
  ${Intro} {
    ${IntroAnimate}
  }

  ${Title} {
    ${TitleAnimate}
  }
`;

export const Container = styled.div`
  text-align: center;
  padding: 65px 0;

  ${XS} {
    margin: 0 auto;
    padding: 30px 15px;
  }

  ${({ animate }) => animate && ContainerAnimate};
`;
