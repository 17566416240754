import styled from '@emotion/styled';
import TiledShapesContainer from '../tiled-shapes-container';
import { Container as MaterialIcon } from '../material-icon';
import { Candy } from '../../styles/colours';
import { Link } from 'react-router-dom';
import { Max900, SM } from '../../styles/responsiveness';

export const Container = styled.section`
  position: relative;
`;

export const Content = styled.div`
  padding: 100px 0 50px 0;

  ${Max900} {
    padding: 40px 0 20px 0;
  }
`;

export const Title = styled.div`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 18px;

  ${Max900} {
    font-size: 22px;
  }
`;

export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
  margin-bottom: 30px;

  ${Max900} {
    font-size: 15px;
  }
`;

export const Status = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  ${MaterialIcon} {
    color: ${Candy};
    font-size: 20px;
    margin: -5px 10px 0 0;
    position: relative;
    top: 1px;
  }
`;

export const Lessons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  padding: 30px 0;

  ${Max900} {
    display: block;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const Lesson = styled.div`
  width: 50%;
  ${Max900} {
    width: auto;
  }
`;

export const LessonCard = styled(Link)`
  height: 141px;
  margin: 8px;
  border: 1px solid #d5dade;
  background-color: #ffffff;
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 #d5dade;
  display: block;
  color: inherit;

  ${Max900} {
    margin: 0 0 15px 0;
    height: auto;
  }
`;

export const LessonCardContent = styled.div`
  padding: 24px;
`;

export const LessonNumber = styled(TiledShapesContainer)`
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #d8e0e5;
  background-size: 60%;
  opacity: 0.5;
`;

export const LessonTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 15px;
`;

export const LessonActionRegion = styled.div`
  display: flex;
`;

export const LessonLearnButton = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;

  ${MaterialIcon} {
    font-size: 18px;
    margin: -6px 10px 0 0;
    position: relative;
    top: 1px;
  }
`;

export const LessonDuration = styled.div`
  margin-left: auto;
  color: #718594;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const CallToActionRegion = styled.div`
  padding: 20px;
  text-align: center;
`;

export const Disclaimer = styled.div`
  color: #718594;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const DisclaimerAstrix = styled.div`
  display: inline-block;
  margin-left: -20px;
  position: relative;
  top: -3px;
  right: -5px;

  svg {
    height: 10px;
    opacity: 0.5;
    g {
      fill: #718594;
    }
  }
`;

export const ImageSpacer = styled.div`
  height: 200px;

  ${SM} {
    height: 100px;
  }
`;
