import styled from '@emotion/styled/macro';
import CrownIcon from '../../../crown-icon';
import TitleShapesContainer from '../../../tiled-shapes-container';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 100px 15px 50px 15px;
`;

export const CardName = styled.div`
  position: absolute;
  top: -10px;
  color: #d5dade;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -100%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RateRegion = styled.div`
  padding: 30px 40px;
  position: relative;
`;

export const PrimaryRateRegion = styled(TitleShapesContainer)`
  padding: 30px 40px;
  position: relative;
  background-size: 60%;
`;

export const RateAstrix = styled.div`
  position: absolute;
  left: 24px;
  top: 26px;
  svg {
    height: 14px;
    width: 14px;
  }
`;

export const Rate = styled.div`
  display: inline-block;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
`;

export const RateLabel = styled.div`
  display: inline-block;
  color: #718594;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  position: relative;
  left: 14px;
  top: -7px;
`;

export const Term = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-shadow: 0 0 6px 0 #f2f3f4;
  margin-bottom: 5px;
`;

export const RepaymentRegion = styled.div`
  padding: 30px 25px 30px 40px;
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 1;
`;

export const RepaymentLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-shadow: 0 0 6px 0 #f2f3f4;
`;

export const RepaymentAmount = styled.div`
  font-size: 32px;
  line-height: 45px;
  text-shadow: 0 2px 0 0 #eaecee;
  position: relative;
  display: inline-block;
  max-width: 100%;
`;

export const RepaymentTotalRegion = styled.div``;

export const RepaymentTotal = styled.div`
  display: inline-block;
  border-radius: 3px;
  background-color: #eaecee;
  font-size: 13px;
  line-height: 17px;
  padding: 2px 7px;

  strong {
    font-weight: 600;
  }
`;

const Card = styled.div`
  border: 1px solid #d5dade;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 0 0 #d5dade;
  position: relative;
  text-align: left;
`;

export const SecondaryCard = styled(Card)`
  height: 284px;
  flex-shrink: 1;
  margin-top: 17px;

  ${CardName} {
    color: #b3bfc9;
  }

  ${RepaymentAmount} {
    &::after {
      content: ' ';
      position: absolute;
      border: 1px solid #00233d;
      opacity: 0.4;
      top: 50%;
      left: -10px;
      right: -20px;
    }
  }

  ${RateRegion} {
    border-bottom: 1px solid #d5dade;
  }
`;

export const PrimaryCard = styled(Card)`
  height: 312px;
  width: 392px;
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 #d5dade;
  margin: 0 -5px;
  z-index: 1;
  flex-shrink: 0;

  ${CardName} {
    color: #b3bfc9;
  }

  ${Rate} {
    font-size: 42px;
    line-height: 52px;
  }

  ${RepaymentAmount} {
    font-size: 40px;
    line-height: 60px;
    display: block;
  }
`;

export const PrimaryCardContentRegion = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const SavingRegion = styled.div`
  color: #ff1e7e;
  min-width: 0;
  max-width: 40%;
  flex-shrink: 1;
  flex-grow: 1;
  padding-right: 15px;
`;

export const SavingLabel = styled.div`
  font-size: 13px;
  line-height: 17px;
`;

export const SavingAmount = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  text-shadow: 0 2px 0 0 #eaecee;
  max-width: 100%;
`;

export const SavingTotal = styled.div`
  font-size: 13px;
  font-style: italic;
  line-height: 17px;
  font-weight: 300;

  strong {
    font-weight: 600;
  }
`;

export const PrimaryCardNameIcon = styled(CrownIcon)`
  margin-bottom: 5px;
`;

export const CallToActionRegion = styled.div`
  padding: 24px 0;
`;
