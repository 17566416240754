import styled from '@emotion/styled';
import { SM } from '../../styles/responsiveness';

export const PartnersBar = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1100px;
  align-items: center;
  justify-content: space-around;

  ${SM} {
    justify-content: center;
  }
`;

export const Partner = styled.div`
  flex-shrink: 1;
  padding: 0 10px;

  svg {
    max-width: 100%;
  }
`;

export const PartnerBrand = styled.div`
  display: block;

  ${SM} {
    display: none;
  }
`;

export const PartnerLogo = styled.div`
  display: none;

  ${SM} {
    display: block;
  }
`;
