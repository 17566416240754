import React from 'react';
import {
  Container,
  SecondaryCard,
  CardName,
  RateRegion,
  PrimaryRateRegion,
  RateAstrix,
  Rate,
  RateLabel,
  Term,
  RepaymentRegion,
  RepaymentLabel,
  RepaymentAmount,
  RepaymentTotalRegion,
  RepaymentTotal,
  PrimaryCard,
  PrimaryCardContentRegion,
  SavingRegion,
  SavingLabel,
  SavingAmount,
  SavingTotal,
  PrimaryCardNameIcon
} from './style';
import { Logo } from '../../../logo';
import Currency from '../../../currency';
import repayment from '../../../../utilities/repayment';
import { primeInterestRate } from '../../../../utilities/constants';
import { Textfit } from 'react-textfit';

const term = 20;
const step = 1;

export default ({ price }) => {
  const baseRepayment = repayment({ price, rate: primeInterestRate, term });
  const lowRepayment = repayment({
    price,
    rate: primeInterestRate - step,
    term
  });
  const highRepayment = repayment({
    price,
    rate: primeInterestRate + step,
    term
  });
  const savingMonthly = baseRepayment.monthly - lowRepayment.monthly;
  const savingTotal = baseRepayment.total - lowRepayment.total;
  return (
    <Container>
      <SecondaryCard>
        <CardName>Bank A</CardName>
        <RateRegion>
          <Rate>{primeInterestRate + step}%</Rate>
          <RateLabel>Interest Rate</RateLabel>
          <Term>Over {term} years</Term>
        </RateRegion>
        <RepaymentRegion>
          <RepaymentLabel>Monthly Repayment</RepaymentLabel>
          <RepaymentAmount>
            <Currency>{Math.round(parseFloat(highRepayment.monthly))}</Currency>
          </RepaymentAmount>
          <RepaymentTotalRegion>
            <RepaymentTotal>
              <strong>Total:&nbsp;</strong>
              <Currency>{Math.round(parseFloat(highRepayment.total))}</Currency>
            </RepaymentTotal>
          </RepaymentTotalRegion>
        </RepaymentRegion>
      </SecondaryCard>
      <PrimaryCard>
        <CardName>
          <PrimaryCardNameIcon />
          Best Offer
        </CardName>
        <PrimaryRateRegion>
          <RateAstrix>
            <Logo />
          </RateAstrix>
          <Rate>{primeInterestRate - step}%</Rate>
          <RateLabel>Interest Rate</RateLabel>
          <Term>Over {term} years</Term>
        </PrimaryRateRegion>
        <PrimaryCardContentRegion>
          <RepaymentRegion>
            <RepaymentLabel>Monthly Repayment</RepaymentLabel>
            <RepaymentAmount>
              <Textfit mode="single" forceSingleModeWidth={true}>
                <Currency>
                  {Math.round(parseFloat(lowRepayment.monthly))}
                </Currency>
              </Textfit>
            </RepaymentAmount>
            <RepaymentTotalRegion>
              <RepaymentTotal>
                <strong>Total:&nbsp;</strong>
                <Currency>
                  {Math.round(parseFloat(lowRepayment.total))}
                </Currency>
              </RepaymentTotal>
            </RepaymentTotalRegion>
          </RepaymentRegion>
          <SavingRegion>
            <SavingLabel>Save</SavingLabel>
            <SavingAmount>
              <Textfit mode="single" forceSingleModeWidth={true}>
                <Currency>{Math.round(savingMonthly)}</Currency> pm
              </Textfit>
            </SavingAmount>
            <SavingTotal>
              a total of{' '}
              <strong>
                <Currency>{Math.round(savingTotal)}</Currency>
              </strong>
            </SavingTotal>
          </SavingRegion>
        </PrimaryCardContentRegion>
      </PrimaryCard>
      <SecondaryCard>
        <CardName>Bank B</CardName>
        <RateRegion>
          <Rate>{primeInterestRate}%</Rate>
          <RateLabel>Interest Rate</RateLabel>
          <Term>Over {term} years</Term>
        </RateRegion>
        <RepaymentRegion>
          <RepaymentLabel>Monthly Repayment</RepaymentLabel>
          <RepaymentAmount>
            <Currency>{Math.round(parseFloat(baseRepayment.monthly))}</Currency>
          </RepaymentAmount>
          <RepaymentTotalRegion>
            <RepaymentTotal>
              <strong>Total:&nbsp;</strong>
              <Currency>{Math.round(parseFloat(baseRepayment.total))}</Currency>
            </RepaymentTotal>
          </RepaymentTotalRegion>
        </RepaymentRegion>
      </SecondaryCard>
    </Container>
  );
};
