import React from 'react';
import { ReactComponent as BrandSvg } from './brand.svg';
import { ReactComponent as LogoSvg } from './logo.svg';

export const Brand = props => (
  <BrandSvg {...props} style={{ transform: 'translateY(5%) scale(1.2)' }} />
);

export const Logo = props => (
  <LogoSvg {...props} style={{ transform: 'translateY(0)' }} />
);
