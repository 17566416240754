import React from 'react';
import {
  Container,
  Content,
  Title,
  Features,
  Feature,
  IconWrapper,
  Text,
  DiveIn,
  ImageSpacer,
  ThatsYouWrapper
} from './styles';
import { Cafe, Mouse, Voice, Document, Account } from '../material-icon';
import { CandyCalculator } from '../calculator-icon';
import diveIn from './dive-in.png';
import thatsyou from './thatsyou.png';

export default () => (
  <Container>
    <Content>
      <Title>
        We exist to bring financial power to our{' '}
        <ThatsYouWrapper>
          customers
          <img src={thatsyou} alt="That's you" />
        </ThatsYouWrapper>
        , we want to make financing your home the quickest and easiest part of
        buying a property.
      </Title>
      <Features>
        <Feature>
          <IconWrapper>
            <Cafe />
          </IconWrapper>
          <Text>Our service is 100% FREE</Text>
        </Feature>
        <Feature>
          <IconWrapper>
            <Mouse />
          </IconWrapper>
          <Text>Complete the full application online</Text>
        </Feature>
        <Feature>
          <IconWrapper>
            <Voice />
          </IconWrapper>
          <Text>We negotiate the best interest rate for you</Text>
        </Feature>
        <Feature>
          <IconWrapper>
            <Document />
          </IconWrapper>
          <Text>Industry accurate prequalifications</Text>
        </Feature>
        <Feature>
          <IconWrapper>
            <CandyCalculator />
          </IconWrapper>
          <Text>Latest home loan calculators</Text>
        </Feature>
        <Feature>
          <IconWrapper>
            <Account />
          </IconWrapper>
          <Text>Partnered with 7 financial institutions for more choice</Text>
        </Feature>
      </Features>
    </Content>
    <DiveIn src={diveIn} alt="Dive in" />
    <ImageSpacer />
  </Container>
);
