import React from 'react';
import {
  Switch,
  SwitchIcon,
  SwitchContent,
  SwitchTitle,
  SwitchDescription,
  SwitchTab,
  SwitchTabShadow
} from '../calculator-button';
import * as links from '../../utilities/links';
import { Yellow } from '../calculator-icon';

export default () => (
  <Switch to={links.repayment()}>
    <SwitchIcon>
      <Yellow />
    </SwitchIcon>
    <SwitchContent>
      <SwitchTitle>Repayment Calculator</SwitchTitle>
      <SwitchDescription>What is my monthly repayment?</SwitchDescription>
    </SwitchContent>
    <SwitchTab />
    <SwitchTabShadow />
  </Switch>
);
