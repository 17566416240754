import styled from '@emotion/styled/macro';
import { Midnight, Mountain } from '../../styles/colours';

export default styled.div`
  font-size: 120px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -3px;
  font-family: 'Source Serif Pro';
  color: ${({ mountain }) => (mountain ? Mountain : Midnight)};
`;
