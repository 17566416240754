import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import MaterialIcon from '../material-icon';

export const Container = styled.div``;

const Star = css`
  color: #fbec8e;
  font-size: 1em;
`;

export const Solid = styled(props => <MaterialIcon {...props} icon="star" />)`
  ${Star};
`;
export const Half = styled(props => (
  <MaterialIcon {...props} icon="star_half" />
))`
  ${Star};
`;
export const Empty = styled(props => (
  <MaterialIcon {...props} icon="star_border" />
))`
  ${Star};
`;
