import React from 'react';
import {
  Container,
  Title,
  Intro,
  InputRegion,
  Disclaimer,
  DisclaimerAstrix,
  CallToActionRegion,
  Outro,
  OutroContent,
  OutroLink,
  ComparisonRegion
} from './style';
import * as Input from '../input';
import { CandyInvertedButton } from '../button';
import { ArrowForward } from '../material-icon';
import { Logo } from '../logo';
import Scribble from '../scribble';
import * as links from '../../utilities/links';
import Desktop from './components/desktop';
import Mobile from './components/mobile';
import useSavedPrice from '../../utilities/use-saved-price';
import Currency from '../currency';
import { VisibleOnMDandLarger, VisibleOnSMandSmaller } from '../responsive';

export default () => {
  const [
    formattedPrice,
    onPriceChange,
    price,
    normalizedPrice,
    priceMinValue,
    priceMaxValue
  ] = useSavedPrice();
  return (
    <Container>
      <Title>See what a difference only 1% makes.</Title>
      <Intro>Enter the bond amount to see what you can save.</Intro>
      <InputRegion>
        <Input.Container>
          <Input.Prefix>R</Input.Prefix>
          <Input.Field
            type="text"
            pattern="\d*"
            placeholder="1,400,000"
            value={formattedPrice}
            onChange={onPriceChange}
          />
          {price < priceMinValue && (
            <Input.Hint>
              Using minimum of{' '}
              <strong>
                <Currency>{priceMinValue}</Currency>
              </strong>
              .
            </Input.Hint>
          )}
          {price > priceMaxValue && (
            <Input.Hint>
              Using maximum of{' '}
              <strong>
                <Currency>{priceMaxValue}</Currency>
              </strong>
              .
            </Input.Hint>
          )}
        </Input.Container>
      </InputRegion>
      <ComparisonRegion>
        <VisibleOnMDandLarger>
          <Desktop price={normalizedPrice} />
        </VisibleOnMDandLarger>
        <VisibleOnSMandSmaller>
          <Mobile price={normalizedPrice} />
        </VisibleOnSMandSmaller>
      </ComparisonRegion>
      <Disclaimer>
        <DisclaimerAstrix>
          <Logo />
        </DisclaimerAstrix>
        Based on prime ± 1% avg. difference for our home loan applications
      </Disclaimer>
      <CallToActionRegion>
        <CandyInvertedButton to={links.apply()}>
          Apply online now
          <ArrowForward />
        </CandyInvertedButton>
      </CallToActionRegion>
      <Outro>
        <Scribble />
        <OutroContent>
          If you’re not the “jump-right-in” type – look around! it might be a
          good idea to{' '}
          <OutroLink to={links.affordability()}>
            see what you can afford
          </OutroLink>{' '}
          first.
        </OutroContent>
      </Outro>
    </Container>
  );
};
