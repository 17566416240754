import styled from '@emotion/styled/macro';
import crown from '../crown-icon';

export const Span = styled.span`
  position: relative;
`;

export const Crown = styled(crown)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;
