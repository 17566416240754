import styled from '@emotion/styled';
import { U1, Mountain, Midnight, Candy, U3 } from '../../../../styles/colours';
import { Button } from '../../../../components/button';
import { XS, SM } from '../../../../styles/responsiveness';

export const CallToActionRegion = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;

  ${XS} {
    display: block;
    max-width: 260px;
    margin: 0 auto;
  }
`;

export const CallToActionButton = styled(Button)`
  position: relative;
  border: 1px solid ${U1};
  height: 79px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin: 0 8px;
  padding: 0 20px;
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 ${U1};
  background-color: #ffffff;
  color: ${Mountain};

  opacity: 0;
  transform: scale(0);
  animation: pop 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;

  @keyframes pop {
    0% {
      opacity: 0;
      transform: scale(0.4);
    }

    50% {
      opacity: 1;
      transform: scale(1.05);
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: ${Mountain};
    }
  }

  strong {
    display: block;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 30px;
    margin-top: -5px;
    color: ${Midnight};

    span {
      color: ${Candy};
    }
  }

  img {
    position: absolute;
    top: -34px;
    left: -16px;
    height: 131px;

    ${XS} {
      display: none;
    }
  }

  ${XS} {
    width: auto;
    padding: 0 10px;
    margin-bottom: 12px;
  }
`;

export const KeepScrollingRegion = styled.div`
  padding: 50px 0;

  ${XS} {
    padding: 30px 0;
  }
`;
