import useCookie from './use-cookie';
import { useCurrency } from './currency';
import { priceInitialValue, priceMinValue, priceMaxValue } from './constants';

export default () => {
  const [savedValue, onSavedValueChange] = useCookie(
    'price',
    priceInitialValue
  );
  const [formattedValue, parseOnChange, rawValue] = useCurrency(
    savedValue,
    onSavedValueChange
  );
  let value = rawValue || 0;
  let normalizedValue = value;
  if (normalizedValue < priceMinValue) normalizedValue = priceMinValue;
  if (normalizedValue > priceMaxValue) normalizedValue = priceMaxValue;
  return [
    formattedValue,
    parseOnChange,
    value,
    normalizedValue,
    priceMinValue,
    priceMaxValue
  ];
};
