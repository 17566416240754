import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { ReactComponent as SwitchTabSvg } from './switch-tab.svg';
import { SM, XS } from '../../styles/responsiveness';

export const SwitchRegion = styled.div`
  margin-bottom: 60px;
`;

export const SwitchLabel = styled.div`
  color: #718594;
  font-size: 11px;
  font-weight: 300;
  line-height: 14px;
  margin-bottom: 5px;
`;

export const SwitchTab = styled(SwitchTabSvg)`
  position: absolute;
  height: 100%;
  width: 25px;
  right: -24px;
`;

export const SwitchTabShadow = styled(SwitchTabSvg)`
  display: none;
  position: absolute;
  height: 100%;
  width: 25px;
  right: -23px;
  margin-top: 4px;
  z-index: -1;
  #Rectangle {
    fill: #d5dade;
  }
`;

export const SwitchIcon = styled.div`
  margin-right: 15px;
`;

export const SwitchContent = styled.div``;

export const SwitchTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2px;
  white-space: nowrap;
`;

export const SwitchDescription = styled.div`
  color: #718594;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
`;

export const SwitchShadow = css`
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 #d5dade;

  ${SwitchTabShadow} {
    display: block;
    ${SM} {
      display: none;
    }
  }
`;

export const Switch = styled(Link)`
  border: 1px solid #eaecee;
  border-right: none;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 18px 5px 18px 18px;
  border-radius: 4px;
  cursor: pointer;
  color: #00233d;
  position: relative;
  height: 42px;
  margin-right: 25px;
  text-align: left;

  @media (hover: hover) {
    &:hover {
      color: #00233d;
      background: #f8fafc;

      ${SwitchTab} {
        #Rectangle {
          fill: #f8fafc;
        }
      }
    }
  }
`;

export const SwitchResponsiveContainer = styled.div`
  ${SM} {
    margin: 0 0 0 auto;
  }

  ${Switch} {
    ${SM} {
      padding: 10px 13px;
      border-right: 1px solid #eaecee;
      height: auto;
      margin: 0 0 0 0;
      border-radius: 4px 4px 0 0;
      border-bottom: none;
    }
  }

  ${SwitchRegion} {
    ${SM} {
      margin: 0 0 0 auto;
    }
    ${XS} {
      display: inline-block;
      margin: 0 0 0 100%;
      transform: translateX(-100%);
    }
  }

  ${SwitchDescription} {
    ${SM} {
      display: none;
    }
  }

  ${SwitchIcon} {
    ${SM} {
      width: 24px;
      height: 24px;
    }
  }

  ${SwitchTab} {
    ${SM} {
      display: none;
    }
  }
`;
