import React from 'react';
import styled from '@emotion/styled';
import homepage from './homepage.png';
import { SM } from '../../../../styles/responsiveness';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const HomePageBackground = styled.img`
  margin: -248px auto 0 auto;
  height: 400px;

  ${SM} {
    margin: -110px auto 0 auto;
    height: 177px;
    width: 337.5px;
  }
`;

export default () => (
  <Wrapper>
    <HomePageBackground src={homepage} alt="Homapage background" />
  </Wrapper>
);
