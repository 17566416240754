import styled from '@emotion/styled/macro';
import CrownIcon from '../../../crown-icon';
import TitleShapesContainer from '../../../tiled-shapes-container';
import { XS } from '../../../../styles/responsiveness';
import { Candy } from '../../../../styles/colours';

export const Container = styled.div`
  padding: 30px 0 15px 0;
  text-align: center;
`;

export const TitleRegion = styled.div`
  margin-bottom: 10px;
`;

export const Crown = styled(CrownIcon)``;

export const TitleContent = styled.div`
  color: #b3bfc9;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;

  ${XS} {
    font-size: 14px;
  }
`;

const Card = styled.div`
  border: 1px solid #d5dade;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
`;

export const CardInner = styled.div`
  display: flex;
`;

export const PrimaryCard = styled(Card)`
  z-index: 10;
  box-shadow: 0 12px 14px 0 rgba(234, 236, 238, 0.62), 0 3px 0 0 #d5dade;
`;

export const RateRegion = styled(TitleShapesContainer)`
  width: 40%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 140%;
  ${XS} {
    padding: 0 12px;
  }
`;

export const Rate = styled.div`
  font-size: 42px;
  font-weight: 900;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
`;

export const Astrix = styled.div`
  position: absolute;
  left: -15px;
  top: -22px;
  width: 15px;
`;

export const Term = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-shadow: 0 0 6px 0 #f2f3f4;
  white-space: nowrap;
`;

export const RepaymentRegion = styled.div`
  width: 60%;
  padding: 20px 30px;
  text-align: left;
  ${XS} {
    padding: 20px 12px;
  }
`;

export const RepaymentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-shadow: 0 0 6px 0 #f2f3f4;
  white-space: nowrap;
`;

export const RepaymentAmount = styled.div`
  font-size: 32px;
  line-height: 40px;
  text-shadow: 0 2px 0 0 #eaecee;
  display: inline-block;
  position: relative;
  margin-left: -2px;
`;

export const RepaymentTotal = styled.div`
  border-radius: 3px;
  background-color: #eaecee;
  display: inline-block;
  font-size: 13px;
  line-height: 17px;
  padding: 2px 7px;

  strong {
    font-weight: 600;
  }
`;

export const SavingsRegion = styled.div`
  background-color: #ffc0e4;
  border-radius: 0 0 4px 4px;
  display: flex;
  color: ${Candy};
  font-size: 13px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
`;

export const SavingTitle = styled.div``;

export const SavingAmount = styled.div`
  padding: 0 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  text-shadow: 0 2px 0 0 #eaecee;
`;

export const SavingTotal = styled.div`
  font-style: italic;
  font-size: 13px;

  ${XS} {
    display: none;
  }
`;

export const SecondaryCard = styled(Card)`
  margin: 0 15px;

  ${XS} {
    margin: 0;
  }

  ${RepaymentAmount} {
    &::after {
      content: ' ';
      position: absolute;
      border: 1px solid #00233d;
      opacity: 0.4;
      top: 50%;
      left: -5px;
      right: -10px;
    }
  }
`;
