import React from 'react';
import {
  Section,
  Heading,
  Step,
  StepFlex,
  StepFlexContent,
  StepImage,
  Row,
  Astrix,
  Title,
  Label,
  CalculatorButtonsRegion,
  ButtonRegion,
  DisclaimerAstrix,
  Spacer
} from './styles';
import BigLetter from '../big-letter';
import apply from './apply.png';
import calculators from './calculators.png';
import prequalCert from './prequal-cert.png';
import { ArrowForward } from '../material-icon';
import * as links from '../../utilities/links';
import { CandyInvertedButton } from '../button';
import AffordabilityCalculatorButton from '../affordability-calculator-button';
import RepaymentCalculatorButton from '../repayment-calculator-button';

export default () => (
  <Section>
    <Heading>Your journey to your dream home starts with these steps.</Heading>
    <Step>
      <StepFlex>
        <StepFlexContent>
          <Row>
            <BigLetter>A</BigLetter>
            <Astrix />
          </Row>
          <Title>
            Use our calculators to get an idea of what you can afford before you
            prequalify.
          </Title>
          <Label>Select a calculator:</Label>
          <CalculatorButtonsRegion>
            <ButtonRegion>
              <AffordabilityCalculatorButton />
            </ButtonRegion>
            <ButtonRegion>
              <RepaymentCalculatorButton />
            </ButtonRegion>
          </CalculatorButtonsRegion>
        </StepFlexContent>
      </StepFlex>
      <StepFlex align="flex-end">
        <StepImage src={calculators} alt="Calculators" />
      </StepFlex>
    </Step>
    <Step>
      <StepFlex order={2} align="flex-end">
        <StepImage src={prequalCert} alt="Prequalification wizard images" />
      </StepFlex>
      <StepFlex>
        <StepFlexContent>
          <Spacer height={60} />
          <Row>
            <BigLetter>B</BigLetter>
            <Astrix />
          </Row>
          <Title>
            Prequalify online and get a certificate to show your affordability
          </Title>
          <Label>
            <DisclaimerAstrix />
            By prequalifying you’ve completed 50% of your application
          </Label>
          <br />
          <CandyInvertedButton to={links.prequalify()}>
            Prequalify Now
            <ArrowForward />
          </CandyInvertedButton>
        </StepFlexContent>
      </StepFlex>
    </Step>
    <Step>
      <StepFlex>
        <StepFlexContent>
          <Row>
            <BigLetter>C</BigLetter>
            <Astrix />
          </Row>
          <Title>
            Turn your prequalification into a full application sent to up to 7
            financial institutions
          </Title>
          <br />
          <CandyInvertedButton to={links.apply()}>
            Apply Now
            <ArrowForward />
          </CandyInvertedButton>
        </StepFlexContent>
      </StepFlex>
      <StepFlex align="flex-end">
        <StepImage height={600} src={apply} alt="Apply wizard images" />
      </StepFlex>
    </Step>
  </Section>
);
