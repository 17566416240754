import React from 'react';
import Splash from './components/splash';
import NavBar from '../../components/nav-bar';
import SavingsBar from '../../components/savings-bar';
import HomePageBackground from './components/homepage-image';
import TestimonialsSection from '../../components/testimonials-section';
import LearningSection from '../../components/learning-section';
import FeaturesSection from '../../components/features-section';
import JourneySection from '../../components/journey-section';
import Footer from '../../components/footer';
import Partners from '../../components/partners-bar';
import NoisyContainer from '../../components/noisy-container';
import ContactHeroBar from '../../components/contact-hero-bar';
import { Helmet } from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <title>Home Loans | Get a Loan for Your Dream Home | Bondspark</title>
      <meta
        name="description"
        content="Need a Home Loan? Secure funding for your dream home by applying for a Bondspark Home Loan. Bondspark clients pay 1% less interest on average - apply today!"
      />
    </Helmet>
    <NoisyContainer>
      <NavBar animate={true} />
      <Splash />
    </NoisyContainer>
    <Partners />
    <FeaturesSection />
    <NoisyContainer>
      <SavingsBar />
      <LearningSection />
    </NoisyContainer>
    <HomePageBackground />
    <JourneySection />
    <TestimonialsSection />
    <ContactHeroBar />
    <Footer />
  </>
);
