import { useCallback, useState } from 'react';
import * as Cookies from 'js-cookie';

export default (key, initialValue, saveCookieOptions = null) => {
  const [item, setInnerValue] = useState(() => {
    return Cookies.get(key) || initialValue;
  });

  const setValue = useCallback(
    (value, options = null) => {
      setInnerValue(value);
      Cookies.set(key, value, options || saveCookieOptions);
    },
    [setInnerValue]
  );

  return [item, setValue];
};
