import React from 'react';
import {
  Container,
  TitleRegion,
  Crown,
  CardInner,
  TitleContent,
  PrimaryCard,
  RateRegion,
  Rate,
  Astrix,
  Term,
  RepaymentRegion,
  RepaymentTitle,
  RepaymentAmount,
  RepaymentTotal,
  SavingsRegion,
  SavingTitle,
  SavingAmount,
  SavingTotal,
  SecondaryCard
} from './style';
import repayment from '../../../../utilities/repayment';
import { primeInterestRate } from '../../../../utilities/constants';
import { Logo } from '../../../logo';
import Currency from '../../../currency';

const term = 20;
const step = 1;

export default ({ price }) => {
  const baseRepayment = repayment({ price, rate: primeInterestRate, term });
  const lowRepayment = repayment({
    price,
    rate: primeInterestRate - step,
    term
  });
  const savingMonthly = baseRepayment.monthly - lowRepayment.monthly;
  const savingTotal = baseRepayment.total - lowRepayment.total;
  return (
    <Container>
      <TitleRegion>
        <Crown />
        <TitleContent>The difference only 1% makes</TitleContent>
      </TitleRegion>
      <PrimaryCard>
        <CardInner>
          <RateRegion>
            <Rate>
              <Astrix>
                <Logo />
              </Astrix>
              {primeInterestRate - step}%
            </Rate>
            <Term>Over {term} years</Term>
          </RateRegion>
          <RepaymentRegion>
            <RepaymentTitle>Monthly Repayment</RepaymentTitle>
            <RepaymentAmount>
              <Currency>
                {Math.round(parseFloat(lowRepayment.monthly))}
              </Currency>
            </RepaymentAmount>
            <RepaymentTotal>
              <strong>Total:&nbsp;</strong>
              <Currency>{Math.round(parseFloat(lowRepayment.total))}</Currency>
            </RepaymentTotal>
          </RepaymentRegion>
        </CardInner>
        <SavingsRegion>
          <SavingTitle>Save</SavingTitle>
          <SavingAmount>
            <Currency>{Math.round(parseFloat(savingMonthly))}</Currency> pm
          </SavingAmount>
          <SavingTotal>
            a total of{' '}
            <strong>
              <Currency>{Math.round(parseFloat(savingTotal))}</Currency>
            </strong>
          </SavingTotal>
        </SavingsRegion>
      </PrimaryCard>
      <SecondaryCard>
        <CardInner>
          <RateRegion>
            <Rate>{primeInterestRate}%</Rate>
            <Term>Over {term} years</Term>
          </RateRegion>
          <RepaymentRegion>
            <RepaymentTitle>Monthly Repayment</RepaymentTitle>
            <RepaymentAmount>
              <Currency>
                {Math.round(parseFloat(baseRepayment.monthly))}
              </Currency>
            </RepaymentAmount>
            <RepaymentTotal>
              <strong>Total:&nbsp;</strong>
              <Currency>{Math.round(parseFloat(baseRepayment.total))}</Currency>
            </RepaymentTotal>
          </RepaymentRegion>
        </CardInner>
      </SecondaryCard>
    </Container>
  );
};
