import React from 'react';
import * as Fnb from '../fnb-logo';
import * as Rmb from '../rmb-logo';
import * as Absa from '../absa-logo';
import * as Nedbank from '../nedbank-logo';
import * as Investec from '../investec-logo';
import * as SaHomeloans from '../sa-homeloans-logo';
import * as Standardbank from '../standardbank-logo';
import { PartnersBar, Partner, PartnerBrand, PartnerLogo } from './styles';

export default () => (
  <PartnersBar>
    <Partner>
      <PartnerBrand>
        <Absa.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <Absa.Logo />
      </PartnerLogo>
    </Partner>
    <Partner>
      <PartnerBrand>
        <Standardbank.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <Standardbank.Logo />
      </PartnerLogo>
    </Partner>
    <Partner>
      <PartnerBrand>
        <Fnb.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <Fnb.Logo />
      </PartnerLogo>
    </Partner>
    <Partner>
      <PartnerBrand>
        <Investec.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <Investec.Logo />
      </PartnerLogo>
    </Partner>
    <Partner>
      <PartnerBrand>
        <Rmb.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <Rmb.Logo />
      </PartnerLogo>
    </Partner>
    <Partner>
      <PartnerBrand>
        <Nedbank.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <Nedbank.Logo />
      </PartnerLogo>
    </Partner>
    <Partner>
      <PartnerBrand>
        <SaHomeloans.Brand />
      </PartnerBrand>
      <PartnerLogo>
        <SaHomeloans.Logo />
      </PartnerLogo>
    </Partner>
  </PartnersBar>
);
