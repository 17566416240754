import React from 'react';
import { Container, Solid, Empty, Half } from './style';

export default ({ score, ...props }) => {
  let children = [];
  for (let i = 0; i < 5; i++) {
    let item = <Empty key={i} />;
    if (i + 0.5 === score) item = <Half key={i} />;
    else if (i + 1 <= score) item = <Solid key={i} />;

    children = [...children, item];
  }

  return <Container {...props} children={children} />;
};
